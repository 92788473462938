<template>
    <loader-overlay
        :loading="loading"
    >
        <div class="bg-white cyrrus-rounded p-5 shadow form-fx mt-4 mb-5">
            <h3 class="h2">{{$t('login.form.heading')}}</h3>
            <b-form @submit.prevent="onSubmit">
                <form-input
                    :label="$t('login.form.username')"
                    v-model="username"
                    :validation="$v.username"
                    id="index.username"
                />

                <form-input
                    type="password"
                    :label="$t('login.form.password')"
                    v-model="password"
                    :validation="$v.password"
                    id="index.password"
                />

                <slot name="errors"></slot>

                <b-alert
                    v-if="error"
                    variant="danger"
                    show
                >
                    {{$t('login.form.invalidUsernameOrPassword')}}
                </b-alert>

                <div class="mt-4">
                    <b-btn
                        variant="primary"
                        type="submit"
                        :disabled="loading"
                    >
                        <span class="iconify mr-1" data-icon="mdi:key"></span> {{$t('login.form.submit')}}
                    </b-btn>
                </div>
            </b-form>
        </div>
    </loader-overlay>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'LoginForm',
    data () {
        return {
            username: null,
            password: null,
            error: false,
            loading: false
        }
    },
    methods: {
        async onSubmit () {
            this.$v.$reset()
            if (this.$v.$invalid) {
                return this.$v.$touch()
            }
            this.error = false
            this.loading = true
            this.$nextTick(async () => {
                try {
                    await this.$api.auth.login(this.username, this.password)
                    this.$emit('logged-in')
                } catch (error) {
                    console.error(error)
                    this.error = true
                } finally {
                    this.$nextTick(() => {
                        this.loading = false
                    })
                }
            })
        }
    },
    validations: {
        username: {
            required
        },
        password: {
            required
        }
    }
}
</script>
