<template>
<section class="section-narrow">
  <div class="section-content">
      <div class="container">
          <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                  <h1>{{$t('login.heading')}}</h1>

                  <p>{{$t('login.subtitle')}}</p>

                  <div class="row">
                      <div class="col-12 col-md-8">
                          <login-form
                              @logged-in="onLoggedIn"
                          >
                            <template #errors>
                                <div class="alert alert-danger" v-if="showError">
                                    {{$t('login.error')}}
                                </div>
                            </template>
                          </login-form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
</template>

<script>
import LoginForm from './Components/LoginForm.vue'

export default {
    name: 'Login',
    components: {
        LoginForm
    },
    data () {
        return {
            showError: false
        }
    },
    mounted () {
        if (this.$user.isLoggedIn) {
            this.$router.push({ name: 'AdminIndex' }).catch(() => [])
        }
    },
    computed: {
        isLoggedIn () {
            return this.$user.isLoggedIn
        }
    },
    watch: {
        isLoggedIn (newValue, oldValue) {
            if (!oldValue && newValue) {
                this.$nextTick(() => {
                    this.$router.push({ name: 'AdminIndex' }).catch(() => [])
                })
            }
        }
    },
    methods: {
        onLoggedIn () {
            this.$user.reload()
                .then(() => {
                    if (!this.$user.isLoggedIn) {
                        this.showError = true
                    }
                }).catch((error) => {
                    console.error(error)
                    this.showError = true
                })
        }
    }
}
</script>
